<template>
  <div
    @mouseenter="onClickBtn"
    class="video-widget-container"
    :style="textStyles"
  >
    <iframe
      ref="frame"
      width="290"
      height="170"
      :src="'https://www.youtube.com/embed/' + videoId"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import setStyles from "@/utils/setStyles";
import { updateRespond } from "@/utils/clickAction";
import { youtubeGetID } from "@/utils/parseVideo";

export default {
  name: "SvImageWidget",
  props: ["widgetIdx"],
  computed: {
    ...mapGetters(["activeStep", "step"]),
    widget() {
      return (
        this.activeStep &&
        this.activeStep.objects &&
        this.activeStep.objects[this.widgetIdx]
      );
    },
    videoId() {
      return youtubeGetID(this.widget.configs.videoUrl);
    },
    textStyles() {
      const isConfigs = this.widget.configs && this.widget.configs.actionType;
      return {
        ...setStyles(this.widget),
        cursor: isConfigs !== "none" && isConfigs ? "pointer" : ""
      };
    }
  },
  mounted() {
    this.$emit("hideLoader");
  },
  methods: {
    onClickBtn() {
      this.$refs.frame.click;
      this.$store.dispatch("updateRespond", {
        data: {
          widgetType: "video",
          widgetName: "youtube.com/embed/" + this.videoId,
          actionType: "video",
          actionData: "clicked"
        },
        idx: this.widgetIdx,
        step: this.step
      });
      updateRespond();
    }
  }
};
</script>

<style scoped>
.video-widget-container {
  display: flex;
}
.video-widget-container:after {
  transition: all 0.6s ease;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, var(--border-color) 100%, transparent 100%)
      top left no-repeat,
    linear-gradient(
        90deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      top center repeat-x,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) top right
      no-repeat,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top left
      no-repeat,
    linear-gradient(
        0deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      center left repeat-y,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom
      left no-repeat,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom
      left no-repeat,
    linear-gradient(
        90deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      bottom center repeat-x,
    linear-gradient(90deg, var(--border-color) 100%, transparent 100%) bottom
      right no-repeat,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) top right
      no-repeat,
    linear-gradient(
        0deg,
        transparent calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2),
        var(--border-color) calc(var(--gap-size) / 2 + var(--dash-size)),
        transparent calc(var(--gap-size) / 2 + var(--dash-size))
      )
      center right repeat-y,
    linear-gradient(0deg, var(--border-color) 100%, transparent 100%) bottom
      right no-repeat;
  background-size: var(--dash-size) var(--border-weight),
    calc(var(--dash-size) + var(--gap-size)) var(--border-weight),
    var(--dash-size) var(--border-weight), var(--border-weight) var(--dash-size),
    var(--border-weight) calc(var(--dash-size) + var(--gap-size)),
    var(--border-weight) var(--dash-size);
}
.video-widget-container iframe {
  position: relative;
  z-index: 10;
}
.video {
  z-index: 999999;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
